import React, { useState, useEffect, useRef } from "react";
import Logo from "../../Resources/logo.svg";
import x from "../../Resources/x.svg";
import MenuBar from "../../Resources/menu.svg";
import "./NavBar.scss";
import { Link } from "react-router-dom";
// import LangSwitcher from "../LangSwitcher/LangSwitcher";

function NavBar(props) {
  const [selected, setSelected] = useState(0);
  const toggle = (i) => {
    window.scrollTo(0, 0);
    return setSelected(i);
  };

  const [navbar, setNavbar] = useState(false);
  const changeBackground = () => {
    if (window.scrollY >= 10) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  const [showSideMenu, setShowSideMenu] = useState(false);
  const toggleSideMenu = () => {
    setShowSideMenu(!showSideMenu);
  };

  const sideMenuRef = useRef();
  const barRef = useRef();
  useEffect(() => {
    window.onclick = (event) => {
      if (event.target.contains(barRef.current)) {
      } else if (showSideMenu && !event.target.contains(sideMenuRef.current)) {
        toggleSideMenu();
        document.body.style.overflow = "unset";
      }
    };
  }, [showSideMenu]);

  window.addEventListener("scroll", changeBackground);
  return (
    <div className="navcontainer">
      <div
        className={showSideMenu ? "sidebar-show" : "sidebar-hide"}
        ref={sideMenuRef}
      >
        <img
          className="x-button"
          src={x}
          alt="X"
          onClick={() => {
            toggleSideMenu();
            document.body.style.overflow = "unset";
          }}
        />
        {props.data.map((item, i) => {
          return (
            <div className="body">
              <Link
                to={item.url}
                className={selected !== i ? "unselected" : "selected"}
                onClick={() => toggle(i)}
              >
                {item.title}
              </Link>
            </div>
          );
        })}
        {/* <LangSwitcher /> */}
      </div>
      <nav
        className={navbar ? "navbar active" : "navbar"}
        onClick={() => {
          if (showSideMenu) {
            toggleSideMenu();
            document.body.style.overflow = "unset";
          }
        }}
      >
        <img
          className="menu-bar"
          src={MenuBar}
          alt="hamburger-menu"
          onClick={() => {
            toggleSideMenu();
            document.body.style.overflow = "hidden";
          }}
          ref={barRef}
        />
        <Link className="navbar-header" to={"/"} onClick={() => toggle(0)}>
          <div className="navbar-logo">
            <img className="img" src={Logo} alt="codejamlogo" />
          </div>
          <div className="body-header">Code.Jam( )</div>
        </Link>
        <div className="menu-items">
          {props.data.map((item, i) => {
            return (
              <div className="body">
                <Link
                  to={item.url}
                  className={selected !== i ? "unselected" : "selected"}
                  onClick={() => toggle(i)}
                >
                  {item.title}
                </Link>
              </div>
            );
          })}
          {/* <LangSwitcher /> */}
          <div className="body">
            <a
              className="a-nodecoration"
              href="https://forms.gle/hm4kVE18CdFnAmj28"
              target="_blank"
              rel="noreferrer noopener"
            >
              Apply
            </a>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default NavBar;
